<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import loadSections from "@/mixins/loadSections";
import firebaseBindOrder from "@/mixins/firebase/bindOrder";
import getMenu from "@/mixins/getMenu";

export default {
  name: "MenuOrder",

  metaInfo: { title: "Menu Order" },

  extends: View,

  mixins: [
    getMenu,
    firebaseBindOrder,
    loadSections([
      "layout-header-menu",
      "public-menu",
      "layout-footer-menu-order",
      "layout-footer-basic",
    ]),
  ],
};
</script>
